import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import one from "../../Assets/Projects/1.png";
import two from "../../Assets/Projects/2.png";
import three from "../../Assets/Projects/3.png";
import four from "../../Assets/Projects/4.png";
import five from "../../Assets/Projects/5.png";


function Projects() {
  return (
    <Container fluid className="project-section" id="project">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple">Roadmap </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few Things That we are going to do ...
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={one}
              isBlog={false}
              title="Phase - 1"
              line1='IDEA / DEVELOPMENT'
              line2='FAIR LAUNCH'
              line3='BSC TRENDING'
              line4='TELEGRAM MARKETING'
              line5='TWITTER MARKETING'
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={two}
              isBlog={false}
              title="Phase - 2"
              line1='DEXTOOL TRENDING $ UPDATE'
              line2='CG LISTING'
              line3='CMC LISTING'
              line4='MOONTOK LISTING'
              line5='LIST IN ALL CRYPTO SITES'
            />
          </Col>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={three}
              isBlog={false}
              title="Phase - 3"
              line1='COMMUNITY REWARDS'
              line2='DAPPS LAUNCH'
              line3='UTILITY LAUNCH'
              line4='CEX LISTING'
              line5='MANY MORE COMING - TBA'
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;

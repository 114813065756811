import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <span className="purple">$BART2 : </span>
            the meme token inspired by a beloved animated character. Have fun, share memes, earn, and join us on a journey to the Moon!
            <br />
            <br />
            BART 2.0 is here to make memecoins great again. Launched stealth with no presale, $BART 2.0 is a coin for the people, forever. Fueled by pure memetic power, let $BART 2.0 show you the way.
          </p>
          {/* <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Dapps & utilites
            </li>
            <li className="about-activity">
              <ImPointRight /> Giveaway & Rewards
            </li>
          </ul> */}

          <p style={{ color: "rgb(155 126 172)" }}>
            "JOIN THE ADVENTURE"{" "}
          </p>
          <footer className="blockquote-footer">BART 2.0</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
